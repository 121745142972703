import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Flex, Modal, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import { WAIVE_FEE } from 'mutations/transactions';
import { FEE_WAIVER_ELIGIBILITY } from 'queries/transactions';
import { SIZE, STATUS } from 'utilities/constants';
import {
  formatDateToSubmit,
  isFeeWaiverScheduledDowntime,
} from 'utilities/datesAndTimes';
import { formatTransactionDescription } from 'utilities/helpers';

const FeeWaiverModal = ({ transaction, hide, open, ...modalProps }) => {
  const { accountId } = useParams();
  const [eligible, setEligible] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [feeWaiverFailure, setFeeWaiverFailure] = useState(undefined);

  const SCHEDULED_DOWNTIME_ERROR_MSG =
    'Fee waivers likely will not work between 8-8:15pm EST, please retry after 8:15pm EST.';

  const ERROR_MSG =
    'Failed to waive fee. Please retry or escalate to R2 to complete the process';

  const [checkEligibility, eligibilityQuery] = useLazyQuery(
    FEE_WAIVER_ELIGIBILITY,
    {
      onCompleted: (data) => {
        setEligible(!!data?.adjustmentEligibility?.eligible);
      },
    },
  );

  const [waiveFee, waiveFeeMutation] = useMutation(WAIVE_FEE, {
    refetchQueries: ['TransactionsQuery', 'FeeWaiverEligibility'],
  });

  const handleFeeWaiver = async () => {
    setFeeWaiverFailure(undefined);
    try {
      await waiveFee({
        variables: {
          accountId,
          postedDate: formatDateToSubmit(transaction.postedAt),
          transactionId: transaction.transactionId,
        },
      });
      setShowSuccess(true);
    } catch {
      setFeeWaiverFailure({ scheduled: isFeeWaiverScheduledDowntime() });
    }
  };

  const closeModal = () => {
    setEligible(false);
    setShowSuccess(false);
    setFeeWaiverFailure(undefined);
    hide();
  };

  useEffect(() => {
    if (open && transaction) {
      checkEligibility({
        variables: {
          accountId,
          postedDate: formatDateToSubmit(transaction.postedAt),
          transactionId: transaction.transactionId,
        },
      });
    }
  }, [open, transaction]);

  return (
    <Modal
      {...modalProps}
      data-testid="waive-fee-modal"
      open={open}
      width={640}
      title="Waive Fee"
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={handleFeeWaiver}
      okText="Waive Fee"
      okButtonProps={{
        'data-testid': 'waive-fee-button',
        disabled: !eligible || eligibilityQuery.loading || showSuccess,
      }}
      confirmLoading={waiveFeeMutation.loading}
    >
      <QueryBoundary
        query={eligibilityQuery}
        message="Checking fee waiver eligibility"
        errorMessage="Failed to retrieve eligibility"
      >
        <Flex
          justify="center"
          align="center"
          vertical={true}
          style={{ position: 'relative', paddingTop: 'var(--spacing-lg)' }}
        >
          {eligible ? (
            <Typography.Text
              data-testid="confirmation-message"
              style={{ marginBottom: 'var(--spacing-md)' }}
            >
              Are you sure you would like to waive this fee?
            </Typography.Text>
          ) : (
            <Alert
              data-testid="already-waived-message"
              banner
              type="warning"
              message="The following fee has already been waived"
              style={{ marginBottom: 'var(--spacing-md)' }}
            />
          )}

          <Typography.Text strong style={{ marginBottom: 'var(--spacing-lg)' }}>
            {formatTransactionDescription(transaction)}
          </Typography.Text>

          {showSuccess && (
            <SectionMessage
              data-testid="waive-fee-success"
              cover={true}
              status={STATUS.SUCCESS}
              size={SIZE.SM}
              text="Fee successfully waived."
            ></SectionMessage>
          )}

          {!!feeWaiverFailure && (
            <Alert
              banner
              data-testid="waive-fee-failure"
              type="error"
              message={
                feeWaiverFailure.scheduled
                  ? SCHEDULED_DOWNTIME_ERROR_MSG
                  : ERROR_MSG
              }
              style={{ marginBottom: 'var(--spacing-md)' }}
            />
          )}
        </Flex>
      </QueryBoundary>
    </Modal>
  );
};

export default FeeWaiverModal;
