import { useMemo } from 'react';
import { Button, Flex, Modal, Typography, Tabs } from 'antd';
import dayjs from 'dayjs';

import useModalControls from 'hooks/useModalControls';

import { formatDollarsFromCents } from 'utilities/helpers';

const ReferralsDetailModal = ({ referrals = {}, ...modalProps }) => {
  const referralsByYear = useMemo(() => {
    const groupedReferrals = {};

    if (referrals) {
      // calculate min/max year to account for years where there are no referrals
      // for ex: referrals in 2024, but no referrals in 2025

      // Find the minimum year from referredCustomers
      const minYear = Math.min(
        ...referrals.referredCustomers.map((referral) =>
          dayjs(referral?.referredDateTime).year(),
        ),
      );

      // Set the maximum year to the current year
      const maxYear = dayjs().year();

      for (let year = minYear; year <= maxYear; year++) {
        groupedReferrals[year] = {
          totalAmountEarned: 0,
          approvedReferrals: 0,
        };
      }

      referrals?.referredCustomers.forEach((referral) => {
        const year = dayjs(referral.referredDateTime).year();
        if (groupedReferrals[year]) {
          groupedReferrals[year].totalAmountEarned +=
            referral.amountStatementCredit;
          groupedReferrals[year].approvedReferrals += 1;
        }
      });
    }

    return groupedReferrals;
  }, [referrals]);

  const tabItems = Object.keys(referralsByYear)
    .map((year) => ({
      key: year,
      label: year,
      children: (
        <div style={{ paddingTop: 'var(--spacing-md)' }}>
          <Typography.Paragraph data-testid="approved-referrals">
            Count of Approved Referrals:{' '}
            <strong data-testid="approved-count">
              {referralsByYear[year].approvedReferrals}
            </strong>
          </Typography.Paragraph>
          <Typography.Paragraph data-testid="total-amount-earned">
            Total Amount Earned:{' '}
            <strong data-testid="total-earned">
              {formatDollarsFromCents(referralsByYear[year].totalAmountEarned)}
            </strong>
          </Typography.Paragraph>
        </div>
      ),
    }))
    .sort((a, b) => b.key - a.key);

  return (
    <Modal
      centered
      width={480}
      cancelButtonProps={{ hidden: true }}
      title="Referrals Details"
      {...modalProps}
    >
      {tabItems.length ? (
        <Tabs defaultActiveKey={tabItems[0]?.key} items={tabItems} />
      ) : (
        <div style={{ paddingTop: 'var(--spacing-md)' }}>
          <Typography.Paragraph>
            <strong data-testid="no-approved-referrals">
              No Approved Referrals
            </strong>
          </Typography.Paragraph>
        </div>
      )}
    </Modal>
  );
};

const Referrals = ({ referrals }) => {
  const referralsDetailsModal = useModalControls();

  return (
    <>
      <ReferralsDetailModal referrals={referrals} {...referralsDetailsModal} />
      <Flex
        align="center"
        justify="space-between"
        gap="var(--spacing-xxs)"
        wrap="wrap"
      >
        <div> {referrals ? 'Eligible' : 'Not Eligible'} </div>
        {referrals && (
          <Button
            type="link"
            size="small"
            onClick={referralsDetailsModal.show}
            style={{ padding: 0 }}
          >
            View Details
          </Button>
        )}
      </Flex>
    </>
  );
};

export default Referrals;
