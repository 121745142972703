// background worker to process auto-events filtering and sorting.

const workerTask = () => {
  self.onmessage = (e) => {
    const { filter, type, payload } = e.data;
    if (type === 'autoevents' && payload) {
      let items = JSON.parse(payload);
      const filteredItems =
        filter?.trim().length > 0
          ? items.filter((item) =>
              item.localizedText?.toLowerCase().includes(filter.toLowerCase()),
            )
          : items;

      const sortedItems = filteredItems.sort(
        (a, b) =>
          new Date(b.localizedDate?.toString()) -
          new Date(a.localizedDate?.toString()),
      );
      self.postMessage(JSON.stringify({ payload: sortedItems, filter }));
    }
  };
};

// load webworkers in apps that uses cra.
let code = workerTask.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const autoEventsWorker = URL.createObjectURL(blob);

export default autoEventsWorker;
