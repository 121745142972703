import { gql } from '@apollo/client';

const REQUEST_CREDIT_BALANCE_REFUND = gql`
  mutation RequestCreditBalanceRefund($accountId: String!, $amount: Long) {
    requestCreditBalanceRefund(accountId: $accountId, amount: $amount) {
      success
    }
  }
`;

export { REQUEST_CREDIT_BALANCE_REFUND };
