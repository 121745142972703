import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

// Performs a nested search for active route menu key
// also identifies parent keys for automatic submenu opening
let findKeys = (routesToMatch, pathname) => {
  let activeRouteKey = '';
  let activeParentKeys = [];

  routesToMatch.some((route) => {
    if (route.children) {
      let [activeSubKey, openSubKeys] = findKeys(route.children, pathname);
      activeRouteKey = activeSubKey;
      if (activeRouteKey) {
        activeParentKeys = [route.key, ...openSubKeys];
      }
    } else if (matchPath(pathname, route.path)) {
      activeRouteKey = route.key;
    }
    return !!activeRouteKey;
  });

  return [activeRouteKey, activeParentKeys];
};

// Keeps track of the active nav key based on the current application route
// Automatically opens parents of sub-menu items
// I'm annoyed ant design doesnt have this functionality out of the box.
const useNavigationKeyManager = (routesToMatch = []) => {
  let { pathname } = useLocation();
  let [activeRouteKey, activeParentKeys] = findKeys(routesToMatch, pathname);
  let [openKeys, setOpenKeys] = useState([]);

  let toggleOpenKey = (key) => {
    let keyIndex = openKeys.findIndex((openKey) => openKey === key);
    if (keyIndex > -1) {
      setOpenKeys([
        ...openKeys.slice(0, keyIndex),
        ...openKeys.slice(keyIndex + 1),
      ]);
    } else {
      setOpenKeys([...openKeys, key]);
    }
  };

  useEffect(() => {
    setOpenKeys([...openKeys, ...activeParentKeys]);
  }, [activeRouteKey]);

  return [activeRouteKey, openKeys, toggleOpenKey];
};

export default useNavigationKeyManager;
