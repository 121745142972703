import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Flex } from 'antd';

import {
  setNextStep,
  setAccountInGoodStanding,
  setReissueDecline,
  resetSendCardState,
  useSendCardState,
  SEND_CARD_STEPS,
} from './sendCardState';
import StepContainer from '../StepContainer';
import AccountStatusTable from '../AccountStatusSection/AccountStatusTable';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import useAnalytics from 'hooks/useAnalytics';
import useAccountQuery from 'hooks/useAccountQuery';
import { ANALYTICS_EVENTS, STATUS, SIZE } from 'utilities/constants';

const LOST_STOLEN_STATUS_BOUNDARIES = Object.freeze({
  BK: [],
  CC: [],
  CL: [],
  CO: ['BD', 'DC', 'ST'],
  CR: ['Bankruptcy', 'BD', 'PD', 'DC'],
  CW: ['04', '05', '07', '08', '09', '10'],
  EX: [],
  FR: [],
  SF: [],
  TW: ['L4', 'L5', 'L7', 'L8', 'L9'],
  WA: [],
  WB: [],
  XF: [],
});

const REPLACEMENT_STATUS_BOUNDARIES = Object.freeze(
  Object.assign({}, LOST_STOLEN_STATUS_BOUNDARIES, {
    CW: ['04', '05', '06', '07', '08', '09', '10'],
    MX: [],
    PC: [],
    PP: [],
    RD: [],
    RP: [],
    RQ: [],
    RV: [],
  }),
);

const AccountStandingStep = () => {
  const { accountId, customerId } = useParams();
  const { trackEvent } = useAnalytics();
  const { accountInGoodStanding, customerHasCard, reissueDecline } =
    useSendCardState();
  const [blockingStatuses, setBlockingStatuses] = useState([]);

  const { statuses, accountQuery } = useAccountQuery(customerId, accountId);

  useEffect(() => {
    const boundarySet = customerHasCard
      ? REPLACEMENT_STATUS_BOUNDARIES
      : LOST_STOLEN_STATUS_BOUNDARIES;

    let hasReissueDecline = statuses.find((entry) => entry.statusCode === 'RD');

    const activeBlockingStatuses = statuses.reduce(
      (statuses, currentStatus, index) => {
        const reasonCodes = boundarySet[currentStatus.statusCode];
        let isBlockingStatus = false;

        if (reasonCodes) {
          isBlockingStatus = reasonCodes.length === 0; // Status alone is enough to block
          if (!isBlockingStatus) {
            isBlockingStatus = reasonCodes.some((reasonCode) => {
              return reasonCode === currentStatus.reasonCode;
            });
          }
        }

        if (isBlockingStatus) {
          const {
            statusCode,
            reasonCode,
            description,
            startDate,
            stopDate,
            date,
          } = currentStatus;
          statuses.push({
            key: `blocking-status-${index}-${statusCode}-${reasonCode}`,
            reasonCode,
            statusCode,
            description,
            startDate,
            stopDate,
            date,
          });
        }

        return statuses;
      },
      [],
    );

    const isGood = !activeBlockingStatuses.length;
    setReissueDecline(hasReissueDecline);
    setBlockingStatuses(activeBlockingStatuses);
    setAccountInGoodStanding(isGood);

    trackEvent(ANALYTICS_EVENTS.SEND_CARD_ACCOUNT_STANDING, {
      customerId,
      accountId,
      accountInGoodStanding: isGood,
      blockingStatuses: activeBlockingStatuses,
    });
  }, [statuses]);

  const handleContinue = () => {
    if (customerHasCard) {
      setNextStep(SEND_CARD_STEPS.ADDRESS_REVIEW);
    } else {
      setNextStep(SEND_CARD_STEPS.CARD_LOSS_DETAILS);
    }
  };

  const getMessageProps = () => {
    const props = {
      status: STATUS.SUCCESS,
      size: SIZE.SM,
      text: 'Account is in good standing',
    };

    if (reissueDecline) {
      props.status = STATUS.INFORMATION;
      props.text = (
        <Alert
          type="info"
          banner
          message={
            <span>
              Account is in good standing but has the 'Reissue Decline' status.{' '}
              <br /> You will be able to submit a security report and transfer
              the account but a new card will not be sent.
              <br />
              Submit a Reissue Decline Ticket in Zendesk after completing this
              workflow.
            </span>
          }
        ></Alert>
      );
    }

    return props;
  };

  return (
    <>
      <StepContainer
        title="Verify Account Standing"
        onContinue={handleContinue}
        onExit={resetSendCardState}
        buttonProps={{
          disabled: accountQuery.loading || !accountInGoodStanding,
        }}
      >
        <QueryBoundary
          query={accountQuery}
          mode={QueryBoundary.MODE.MESSAGE}
          loadingMessage={`Verifying account status`}
        >
          {blockingStatuses.length ? (
            <Flex vertical={true} gap="var(--spacing-lg)">
              <Alert
                showIcon={true}
                type="warning"
                banner
                message="Account ineligible. The following account statuses are preventing card replacement on this account."
              />
              <AccountStatusTable statuses={blockingStatuses} />
            </Flex>
          ) : (
            <SectionMessage {...getMessageProps()}></SectionMessage>
          )}
        </QueryBoundary>
      </StepContainer>
    </>
  );
};

export default AccountStandingStep;
