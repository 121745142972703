import { Button, Card, Flex, Popconfirm, Tooltip, Typography } from 'antd';
import { CalendarOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { getDefaultPayments } from 'utilities/helpers';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';
import { COLORS } from 'utilities/styles';

import {
  RECOVERIES_STEPS,
  setNextStep,
  setAcceptedOffer,
  setFundingAccount,
  setPaymentCadence,
  setPaymentDates,
} from './recoveriesState';

const { Text, Title } = Typography;

const PIFOfferCard = ({ pifOffer, hasPendingPayment, hasScheduledPayment }) => {
  const pifBalance = (pifOffer?.amount / 100).toFixed(2);
  const durationMonths = pifOffer?.durationMonths;
  const offerDurationFromToday = dayjs().add(pifOffer?.durationMonths, 'month');

  const payments = getDefaultPayments(
    pifBalance,
    dayjs(),
    offerDurationFromToday,
    durationMonths,
  );

  const estimatedPaymentsText = (
    <Text>
      <CalendarOutlined /> <strong>With a start date of today,</strong> a
      customer would owe around:
      <br />
      <strong>${payments.monthly} | monthly</strong>, over {durationMonths}{' '}
      months <br />
      <strong>${payments.bimonthly} | every 2 weeks</strong>, over{' '}
      {durationMonths} months
      <br />
      <strong>${payments.weekly} | weekly</strong>, over {durationMonths} months
    </Text>
  );

  const offerTitle = (
    <>
      {pifOffer.key}. Pay in Full
      <br />
      <Text type="secondary">
        100%, variable timeline{' '}
        <Tooltip title={`Max duration is ${durationMonths} months`}>
          <InfoCircleTwoTone style={{ color: COLORS.blue1 }} />
        </Tooltip>
      </Text>
    </>
  );

  const onOfferSelection = () => {
    setAcceptedOffer(
      pifBalance,
      pifOffer?.durationMonths,
      pifOffer?.offerId,
      pifOffer?.offerName,
      SETTLEMENT_OFFER_TYPES.PIF,
    );
    setNextStep(RECOVERIES_STEPS.ADD_FUNDING_ACCOUNT);
  };

  const onDebitPaymentSelection = () => {
    setAcceptedOffer(
      pifBalance,
      1,
      pifOffer?.offerId,
      pifOffer?.offerName,
      'PIFOneTimeDebit',
    );
    setPaymentCadence(SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT, [
      {
        amount: `$${pifBalance}`,
        date: formatDateToSubmit(dayjs()),
      },
    ]);
    setFundingAccount('--', 'Debit Card Payment');
    setPaymentDates(dayjs().format('L'), dayjs().add(1, 'day').format('L'));
    setNextStep(RECOVERIES_STEPS.DISCLOSURE);
  };

  const buttons = (
    <Flex gap="var(--spacing-sm)" justify="center">
      <Tooltip
        title={
          hasPendingPayment || hasScheduledPayment
            ? 'Cannot activate with a pending or scheduled payment'
            : ''
        }
      >
        <Popconfirm
          title={<Title level={5}>Select the Payment Method</Title>}
          onCancel={onDebitPaymentSelection}
          onConfirm={onOfferSelection}
          okText="Payment Plan"
          cancelText="One Time Debit Payment"
        >
          <Tooltip
            title={
              hasPendingPayment || hasScheduledPayment
                ? 'Account has a pending or scheduled payment.'
                : ''
            }
          >
            <Button
              key="selectOffer"
              data-testid="selectPifOffer"
              type="primary"
              style={{ width: 225 }}
              disabled={hasPendingPayment || hasScheduledPayment}
            >
              Select Offer
            </Button>
          </Tooltip>
        </Popconfirm>
      </Tooltip>
    </Flex>
  );

  return (
    <Card
      type="inner"
      style={{ minWidth: 350 }}
      title={offerTitle}
      actions={[buttons]}
    >
      Full Balance: ${pifBalance}
      <br />
      <strong>
        Plan Due Date:{' '}
        {dayjs().add(pifOffer?.durationMonths, 'month').format('LL')}
      </strong>
      <br />
      --
      <br /> <br />
      <strong>Estimated Payments</strong>
      <br />
      <br />
      {estimatedPaymentsText}
    </Card>
  );
};

export default PIFOfferCard;
