import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Alert, Flex, Form, Radio, Space, DatePicker, InputNumber } from 'antd';

import { GET_SETTLEMENT_PAYMENT_PLAN } from 'queries/settlements';
import {
  dateIsWithin30Days,
  formatDateToSubmit,
} from 'utilities/datesAndTimes';
import { formatDollarsFromCents } from 'utilities/helpers';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';
import _isEqual from 'lodash/isEqual';

import EstimatedPayments from './EstimatedPayments';
import StepContainer from '../StepContainer';
import {
  RECOVERIES_STEPS,
  setNextStep,
  setPaymentDates,
  setPaymentCadence,
  useRecoveriesState,
  setPifPlanDuration,
} from './recoveriesState';

const { Item } = Form;

// customer service hours are  8:30am to 9pm EST so agents really shouldn't run into this
// but adding this check to prevent any issues
export const isAfterTodayPaymentCutoff = (date) => {
  const todayPaymentCutoffTimeUtc = dayjs().utc().endOf('day').format();
  return date.isSameOrBefore(todayPaymentCutoffTimeUtc);
};

const PaymentPlanDetailsStep = () => {
  const { acceptedOffer, pifPlanDuration, paymentDates, paymentCadence } =
    useRecoveriesState();

  const [paymentDetailsForm] = Form.useForm();

  const [paymentPlanQueryLoading, setPaymentPlanQueryLoading] = useState(false);
  const [paymentPlanSchedule, setPaymentPlanSchedule] = useState([]);
  const [paymentPlanError, setPaymentPlanError] = useState(false);
  const [paymentStartDateResetMsg, setPaymentStartDateResetMsg] = useState('');

  const getDurationMonths = (cadence) => {
    let duration;
    if (pifPlanDuration) {
      return pifPlanDuration;
    } else if (cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME) {
      duration = 1;
    } else {
      duration = acceptedOffer.durationMonths;
    }

    return duration;
  };

  const [getSettlementPaymentPlan, { loading: paymentPlanLoading }] =
    useLazyQuery(GET_SETTLEMENT_PAYMENT_PLAN, {
      onCompleted: ({ paymentPlanSchedule }) => {
        setPaymentPlanError(false);
        const paymentPlan = paymentPlanSchedule?.map((payment) => {
          return {
            date: payment.date,
            amount: formatDollarsFromCents(payment.amount),
          };
        });
        setPaymentPlanSchedule(paymentPlan);
        setPaymentPlanQueryLoading(false);
      },
      onError: () => {
        setPaymentPlanError(true);
      },
    });

  const onDateChange = useCallback(
    (startDate) => {
      setPaymentStartDateResetMsg('');

      const durationMonths = getDurationMonths(paymentCadence.cadence);
      if (startDate) {
        const endDate = dayjs().add(durationMonths, 'month');
        setPaymentDates(startDate.format('L'), endDate.format('L')); // MM/DD/YYYY
      }

      if (startDate && paymentCadence.cadence && durationMonths) {
        setPaymentPlanQueryLoading(true);
        getSettlementPaymentPlan({
          variables: {
            amount: Math.round(Number(acceptedOffer.balance) * 100),
            startDate: formatDateToSubmit(startDate),
            duration: durationMonths,
            cadence: paymentCadence.cadence,
          },
        });
      }
    },
    [paymentCadence.cadence, paymentDates.startDate, pifPlanDuration],
  );

  const onCadenceSelection = useCallback(
    ({ target: { value } }) => {
      setPaymentStartDateResetMsg('');
      setPaymentCadence(value);
      const durationMonths = getDurationMonths(value);

      if (
        value === SETTLEMENTS_CADENCE_VALUES.MONTHLY &&
        paymentDetailsForm.getFieldValue('firstPaymentDate')?.date() >= 29
      ) {
        paymentDetailsForm.setFieldValue('firstPaymentDate', undefined);
        setPaymentStartDateResetMsg(
          'Dates 29-31 are not valid for a MONTHLY cadence. Please select a new date.',
        );
      }

      if (paymentDates.startDate && durationMonths) {
        setPaymentPlanQueryLoading(true);
        getSettlementPaymentPlan({
          variables: {
            amount: Math.round(Number(acceptedOffer.balance) * 100),
            startDate: formatDateToSubmit(paymentDates.startDate),
            duration: durationMonths,
            cadence: value,
          },
        });
      }
    },
    [paymentCadence.cadence, paymentDates.startDate, pifPlanDuration],
  );

  const onPifPlanDurationChange = useCallback(
    (value) => {
      setPaymentCadence(paymentCadence.cadence);
      setPifPlanDuration(value);

      if (paymentDates.startDate && paymentCadence.cadence) {
        setPaymentPlanQueryLoading(true);
        getSettlementPaymentPlan({
          variables: {
            amount: Math.round(Number(acceptedOffer.balance) * 100),
            startDate: formatDateToSubmit(paymentDates.startDate),
            duration: value,
            cadence: paymentCadence.cadence,
          },
        });
      }
    },
    [paymentCadence.cadence, paymentDates.startDate, pifPlanDuration],
  );

  useEffect(() => {
    const paymentPlanHasChanges = !_isEqual(
      paymentCadence.paymentPlanSchedule,
      paymentPlanSchedule,
    );

    const paymentSchedule = paymentPlanHasChanges
      ? paymentPlanSchedule
      : paymentCadence.paymentPlanSchedule;

    if (!paymentPlanQueryLoading) {
      setPaymentCadence(paymentCadence.cadence, paymentSchedule);
    }
  }, [
    paymentPlanQueryLoading,
    paymentPlanSchedule,
    paymentCadence.paymentPlanSchedule,
  ]);

  const onFinish = async () => {
    try {
      await paymentDetailsForm.validateFields();
    } catch {
      return;
    }
    setNextStep(RECOVERIES_STEPS.DISCLOSURE);
  };

  const isDate29thTo31st = (selectedDate) => {
    const date = selectedDate.date();
    return date >= 29 && date <= 31;
  };

  const disabledDate = (d) => {
    return !d || !dateIsWithin30Days(d) || isAfterTodayPaymentCutoff(d);
  };

  return (
    <StepContainer
      title="Payment Plan Details"
      onContinue={onFinish}
      buttonProps={{ disabled: paymentPlanQueryLoading || paymentPlanError }}
    >
      <Flex justify="space-between">
        <Flex vertical={true}>
          <Form
            name="paymentDetailsForm"
            form={paymentDetailsForm}
            layout="vertical"
            initialValues={{
              pifPlanDuration: pifPlanDuration ? pifPlanDuration : null,
              firstPaymentDate: paymentDates.startDate
                ? dayjs(paymentDates.startDate)
                : undefined,
              paymentCadence: paymentCadence.cadence
                ? paymentCadence.cadence
                : null,
            }}
          >
            <Item
              label="Select a Payment Cadence"
              name="paymentCadence"
              rules={[
                {
                  required: true,
                  message: 'Please select a payment cadence.',
                },
              ]}
            >
              <Radio.Group onChange={onCadenceSelection}>
                <Space direction="vertical">
                  <Radio
                    data-testid="monthlyCadence"
                    value={SETTLEMENTS_CADENCE_VALUES.MONTHLY}
                  >
                    Monthly
                  </Radio>
                  <Radio
                    data-testid="biweeklyCadence"
                    value={SETTLEMENTS_CADENCE_VALUES.BIWEEKLY}
                  >
                    Every 2 Weeks
                  </Radio>
                  <Radio
                    data-testid="weeklyCadence"
                    value={SETTLEMENTS_CADENCE_VALUES.WEEKLY}
                  >
                    Weekly
                  </Radio>
                  {acceptedOffer.offerType === SETTLEMENT_OFFER_TYPES.SIF && (
                    <Radio
                      data-testid="onceTimeCadence"
                      value={SETTLEMENTS_CADENCE_VALUES.ONE_TIME}
                    >
                      Lump Sum
                    </Radio>
                  )}
                </Space>
              </Radio.Group>
            </Item>
            {acceptedOffer.offerType === SETTLEMENT_OFFER_TYPES.PIF && (
              <Item
                label="
                  Enter PIF Plan Duration (in months)
                "
                name="pifPlanDuration"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a plan duration in months.',
                  },
                  {
                    /* eslint-disable no-unused-vars */
                    validator: (_, value) => {
                      return new Promise((resolve, reject) => {
                        if (Number(value) > 12) {
                          reject();
                        }
                        resolve();
                      });
                    },
                    message: `Max duration is ${acceptedOffer.durationMonths} months. Please re-enter.`,
                  },
                ]}
              >
                <InputNumber
                  data-testid="pifPlanDurationInput"
                  onChange={onPifPlanDurationChange}
                  style={{ width: 138, marginBottom: 'var(--spacing-sm)' }}
                />
              </Item>
            )}
            <Item
              label="Select a First Payment Date"
              name="firstPaymentDate"
              rules={[
                {
                  required: true,
                  message: 'Please select a first payment date.',
                },
              ]}
              style={{ marginBottom: '0px' }}
            >
              <DatePicker
                data-testid="paymentDatePicker"
                format="MM/DD/YYYY"
                onChange={onDateChange}
                disabledDate={(d) => {
                  if (
                    paymentDetailsForm.getFieldValue('paymentCadence') ===
                    SETTLEMENTS_CADENCE_VALUES.MONTHLY
                  ) {
                    return disabledDate(d) || isDate29thTo31st(d);
                  }
                  return disabledDate(d);
                }}
                style={{ marginBottom: 'var(--spacing-sm)' }}
              />
            </Item>
            {paymentStartDateResetMsg && (
              <Alert
                banner
                type="warning"
                message={paymentStartDateResetMsg}
                style={{ width: '300px' }}
              />
            )}
            {paymentPlanError && (
              <Alert
                banner
                type="error"
                message="Failed to retrieve payment plan. Please try again."
                style={{ width: '250px' }}
              />
            )}
          </Form>
        </Flex>

        <EstimatedPayments loadingQuery={paymentPlanLoading} />
      </Flex>
    </StepContainer>
  );
};

export default PaymentPlanDetailsStep;
